import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import ScreenSizeTooltip from 'components/dist/molecules/ScreenSizeTooltip';
import UserNameText from 'components/dist/molecules/UserNameText';
import { Fragment } from 'react';
import { LoanRoleDto } from "src/backend";
import { RoleType } from "src/constants/loan";
import { useAuth } from 'src/hooks/use-auth';
import { LoanRole } from "src/types/loan";
import { getInitials } from "src/utils/get-initials";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";
import { isRoleABorrower } from 'src/utils/user/is-role-a-borrower';

import { UserAvatar } from "../user/user-avatar";

const MAX_AVATARS = 3

interface RolesAvatarsProps {
    roles: LoanRoleDto[]
}

interface ListTeam {
    team: LoanRoleDto[]
    title: string
}

export const RolesAvatars = (props: RolesAvatarsProps) => {
    const total = props.roles.length;
    const { user } = useAuth();
    const borrowers = props.roles.filter(role => ['BORROWER', 'LEAD_BORROWER'].includes(role.role));
    const contacts = props.roles.filter(role => ['CONTACT'].includes(role.role) && !role.keyContact);
    const keyContacts = props.roles.filter(role => ['CONTACT'].includes(role.role) && role.keyContact);
    const lenders = props.roles.filter(role => !isRoleABorrower(role.role));

    const teamsWithValues: ListTeam[] = [
        { team: lenders, title: 'My Team' },
        { team: borrowers, title: 'Borrowing Team' },
        { team: keyContacts, title: 'Key Contacts' },
        { team: contacts, title: 'Contacts' },
    ].filter(team => team.team.length > 0);

    return <div onClick={(event) => event.preventDefault()}>
        <Tooltip.Provider>
            <ScreenSizeTooltip>
                <ScreenSizeTooltip.Trigger className="-space-x-1 flex overflow-hidden py-1">
                    {[...props.roles].sort(sortByRole)
                        .filter((_, index) => index < MAX_AVATARS)
                        .map((role) =>
                            <UserAvatar
                                key={role.user.id}
                                className='ring-1 ring-white'
                                avatarDocId={role.user.avatarDocId}
                                size="xsm"
                                role={role.role}
                                userId={role.user.id} >
                                {getInitials(getUserDisplayName(role.user))}
                            </UserAvatar>
                        )}
                    {total > MAX_AVATARS && <UserAvatar
                        className='ring-1 ring-black-primary text-black-primary'
                        size="xsm"
                        role={null}
                        userId={null} >
                        +{total - MAX_AVATARS}
                    </UserAvatar>}
                </ScreenSizeTooltip.Trigger>
                <ScreenSizeTooltip.Content
                    align="start"
                    side="bottom"
                    className='text-xs max-w-44 sm:max-w-sm sm:max-w-auto sm:min-w-44 p-4 lg:p-2'
                    alignOffset={0}>
                    <Stack space="sm">
                        {teamsWithValues.map((team, i) => <Fragment key={team.title}>
                            <Stack space="sm">
                                <Text size="inherit" weight="medium">
                                    {team.title}
                                </Text>
                                {team.team.map(role => <UserNameText
                                    isMe={role.user.id === user.id}
                                    key={role.id}
                                    size="inherit"
                                    user={role.user} />)}
                            </Stack>
                            {i < teamsWithValues.length - 1 && <Separator />}
                        </Fragment>)}
                    </Stack>
                </ScreenSizeTooltip.Content>
            </ScreenSizeTooltip>
        </Tooltip.Provider>
    </div>
}

const sortByRole = (a: LoanRole, b: LoanRole) => {
    // sort by role LeadLender first then Lender then LeadBorrower then Borrower
    if (a.role === RoleType.LeadLender && b.role !== RoleType.LeadLender) {
        return -1;
    }
    if (a.role === RoleType.LENDER && b.role === RoleType.LeadBorrower) {
        return -1;
    }
    if (a.role === RoleType.LENDER && b.role === RoleType.BORROWER) {
        return -1;
    }
    if (a.role === RoleType.LeadBorrower && b.role === RoleType.BORROWER) {
        return -1;
    }
    return 1;

}
