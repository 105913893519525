export const moneyFormatter = (amount: number | string, sign: boolean = true): string => {
  return `${sign ? '$' : ''}${moneyFormat(amount)}`;
};

// Just formats a number as USD currency (2300 -> $2,300)
export function formatAsCurrency(
  amount: string | number,
  digits: number = 0
): string {
  if (amount === undefined || amount === null) {
    return "--";
  }

  if (typeof amount == "number") {
    return amount.toLocaleString("us", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: digits,
    });
  } else {
    const number = parseFloat(amount);
    if (!isNaN(number))
      return number.toLocaleString("us", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: digits,
      });
    else return "--";
  }
}

const moneyFormat = (amount) => {
  if (!amount) return '0';

  if (amount >= 1_000_000_000_000) {
    return (amount / 1_000_000_000_000).toFixed(2) + 'T';
  } else if (amount >= 1_000_000_000) {
    return (amount / 1_000_000_000).toFixed(2) + 'B';
  } else if (amount >= 1_000_000) {
    return (amount / 1_000_000).toFixed(2) + 'M';
  } else if (amount >= 1_000) {
    return (amount / 1_000).toFixed(0) + 'K';
  } else if (amount >= 1) {
    return `${amount?.toFixed(0)}`;
  } else {
    return '0';
  }
}